// 放置所有的请求接口地址

// const url = 'https://meituan.thexxdd.cn/apit/'
//const url = "http://127.0.0.1:8000/";
const url = "http://120.76.194.95:8002/";

const urls = class {
  static m() {
    // 注册接口
    const register = `${url}user/register`;
    // 登录接口
    const login = `${url}user/login`;
    // 下载插件
    const plugin = `${url}user/plugin`;
    // 产品接口
    const product_read = `${url}product/read`;
    // 类目接口
    const product_category = `${url}product/category`;
    // 产品详细信息
    const spu_read = `${url}product/spu`;
    // 产品详细信息
    const upload_image = `${url}product/upload_image`;
    // 删除产品
    const delete_spu = `${url}product/delete`;
    // 产品翻译
    const translate_product = `${url}product/translate`;
    // 产品更新
    const update_product = `${url}product/update`;
    // 店铺授权
    const store_permit = `${url}store/permit`;
    // 店铺查询
    const store_read = `${url}store/read`;
    // 利润率更新
    const store_update = `${url}store/update`;
    // 产品上传数据
    const listing_data = `${url}listing/data`;
    // 上传产品
    const listing_run = `${url}listing/run`;
    // 物流数据
    const logistics_create = `${url}logistics/create`;
    const logistics_update = `${url}logistics/update`;
    const logistics_read = `${url}logistics/read `;
    const logistics_delete = `${url}logistics/delete`;
    // 用户注册
    const user_code = `${url}user/send_code`;
    // 修改密码
    const change_password = `${url}user/change`;
     // 修改密码验证码
     const change_code = `${url}user/change_code`;
    // 确认修改密码
    const change_sure = `${url}user/sure`;
    const category = `${url}listing/category`;
    return {
      register,
      login,
      product_read,
      product_category,
      plugin,
      spu_read,
      upload_image,
      delete_spu,
      translate_product,
      update_product,
      store_permit,
      store_read,
      store_update,
      listing_data,
      listing_run,
      logistics_create,
      logistics_update,
      logistics_read,
      logistics_delete,
      user_code,
      change_password,
      change_code,
      change_sure,
      category
    };
  }
};

export default urls;
