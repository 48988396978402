import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    // 首页界面
    path: "/",
    name: "home",
    component: () => import("@/page/Home.vue"),
    // 二级路由
    redirect: "/product",
    children: [
      {
        path: "/product",
        name: "product",
        component: () => import("@/page/Product.vue"),
      },
      {
        path: "/user",
        name: "user",
        component: () => import("@/page/User.vue"),
      },
      {
        path: "/edit",
        name: "edit",
        component: () => import("@/page/Edit.vue"),
      },
      {
        path: "/listing",
        name: "listing",
        component: () => import("@/page/Listing.vue"),
      },
      {
        path: "/store",
        name: "store",
        component: () => import("@/page/Store.vue"),
      },
      {
        path: "/logistics",
        name: "logistics",
        component: () => import("@/page/Logistics.vue"),
      },
    ],
  },
  {
    // 登录界面
    path: "/login",
    name: "login",
    component: () => import("@/page/Login.vue"),
  },
  {
    // category
    path: "/category",
    name: "category",
    component: () => import("@/page/category.vue"),
  },
  {
    // 注册界面
    path: "/register",
    name: "register",
    component: () => import("@/page/Register.vue"),
  },
  {
    // 登录界面
    path: "/agree",
    name: "agree",
    component: () => import("@/page/Agreenment.vue"),
  },
  {
    // 修改密码
    path: "/password",
    name: "password",
    component: () => import("@/page/Password.vue"),
  },
  {
    // 系统介绍
    path: "/introduce",
    name: "introduce",
    component: () => import("@/page/Introduce.vue"),
  },
  {
    // 客服
    path: "/service",
    name: "service",
    component: () => import("@/page/Service.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token"); // 获取 xiaozhu_token，假设你已经在登录时存储了 xiaozhu_token
  if (!token) {
    if (to.path === "/login" || to.path==='/password'  || to.path==='/register' || to.path==='/agree') {
      next();
    } else {
      next("/login");
    }
  } else {
    next(); // 如果已经登录，允许用户继续访问目标页面
  }
});

export default router;
