import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import createStore from './router/store'
import router from './router/index'
import '../style/headtap.css'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

const app = createApp(App)
app.use(ElementPlus, {locale: zhCn})
app.use(createStore)
app.use(router)
Viewer.setDefaults({
    'inline': false, //启用inline模式
    'button': true, //显示右上角关闭按钮
    'navbar': false, //显示缩略图导航
    'title': false, //显示当前图片的标题
    'toolbar': false, //显示工具栏
    'tooltip': true, //显示缩略百分比
    'movable': false, //图片是否可移动
    'zoomable': true, //图片是否可缩放
    'rotatable': false, //图片是否可旋转
    'scalable': false, //图片是否可反转
    'transition': true, //使用css3过度
    'fullscreen': false, //播放时是否全屏
    'keyboard': false, //
  })
app.use(Viewer)
// 请求地址
import urls from './api/api.js'
app.config.globalProperties.$urls = urls
// 请求方法
import request from './api/request.js'
app.config.globalProperties.$request = request
// 弹窗提示
import tips from '../config/Tips.js'
app.config.globalProperties.$tips = tips

app.mount('#app')
