import axios from "axios";
import { ElMessageBox } from "element-plus";

// 创建通用的axios配置
let instance = axios.create({
  responseType: "json",
  headers: { "Content-Type": "application/json" },
});

// http拦截：是在axios请求发出之前给每一个接口携带token去后端校验身份
instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// http拦截：是在axios请求发出之后
instance.interceptors.response.use(
    response =>{
        return response
    },
    error=>{
        if(error.response){
            let errs = error.response.status
            let msg = error.response.message
            let errdata = errs == 401? msg:'服务器发生错误，右上角联系客服解决'
            switch (errs){
                // 没有访问权限，token过期，没有携带token
                case 401:
                ElMessageBox.alert(errdata, '提示',{
                    confirmButtonText: '好的',
                    type:'error',

                })
                .then(res=>{   // 点好的进入这里
                    // 跳转到登录页面
                })
                .catch(err=>{

                })
                break
                    
            }
        }
        return Promise.reject(error.response)  // 返回接口的错误信息
        
    }
)

export default instance