import {createStore} from "vuex"


export default createStore({
    // 创建数据仓库
    state:{
        spu_value:""
    },
    // 使用以下方法调用数据仓库里的数据
    // 同步调用
    mutations:{
        trigger(state,val){
            state.spu_value = val
        }
    },
    // 异步调用
    actions:{
        saveSpu(store){
            store.commit('trigger')
        }
    }
})